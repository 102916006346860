<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar v-if="appVersionInfo.isShowNavBar"
               title="认证小程序授权"
      >
      </nav-bar></template>
    <iframe
      :src="authLink"
      style="width:100%;height:900px;border:none;-webkit-overflow-scrolling: touch;"
    ></iframe>
  </page-view>
</template>
<script>
import appVersionMixins from '@/minxins/app-version-mixins'
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { fetchAuthLink } from '@/model/miniprogram/authority'
import { Button, Toast } from 'vant'
export default {
  name: 'MiniProgarmAuditIframe',
  mixins: [appVersionMixins],
  components: {
    PageView,
    NavBar,
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  props: {},
  data () {
    return {
      authLink: ''
    }
  },
  computed: {},
  watch: {},
  created () { },
  async mounted () {
    await this.getSkipToAuthPage()
  },
  destroyed () { },
  methods: {
    /**
     * 获取要跳转的页面
     */
    async getSkipToAuthPage () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const res = await fetchAuthLink()
      this.authLink = res
      Toast.clear()
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
